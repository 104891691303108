body {
  height: 100%;
  margin: 0;
}

a {
  text-decoration: none;
}

.gear {
  height: 25px;
  filter: invert(100%);
}

.menuAll {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuAlign {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
