.login {
  height: 100vh;
  display: grid;
  grid-template-columns: 1.1fr 2fr;
  grid-template-rows: 20vh 1fr;
  gap: 0 1em;

  .logo {
    width: auto;
    height: auto;
    background-image: url('../../img/svg/Logo.svg');
    background-repeat: no-repeat;
    background-position: center;

    grid-column: 1 /2;
  }

  form {
    width: 90%;
    margin: 30% auto 0;

    grid-column: 1 / 2;
    grid-row: 2;
  }

  .side-img {
    background-image: url('../../img/svg/Side-Panel.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
}
